import { useMemo } from 'react'
import classNames from 'classnames'
import { Controller, Navigation, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import { ProductMiniCard } from '~/components'
import { SectionContainer } from '~/components/SectionContainer'
import { usePage } from '~/hooks'
import styles from './ProductMiniCardsCarousel.module.css'
import {
  ProductMiniCardsCarouselProps,
  ProductMiniCardsSlide,
} from './ProductMiniCardsCarousel.types'

export const ProductMiniCardsCarousel = ({
  products = [],
  width = 'full',
}: ProductMiniCardsCarouselProps) => {
  const page = usePage()
  const isRTM = page?.brand === 'rtm'

  const slides = useMemo(() => {
    const generatedSlides = [] as ProductMiniCardsSlide[]

    const generateSildes = () => {
      const PRODUCTS_PER_SLIDE = 2
      for (let i = 0; i < products.length; i += PRODUCTS_PER_SLIDE) {
        const product1 = products[i]
        const product2 = products[i + 1]

        generatedSlides.push({
          slide: (
            <div className='flex flex-col gap-4 md:flex-row md:gap-10'>
              {product1 && <ProductMiniCard {...product1} />}
              {product2 && <ProductMiniCard {...product2} />}
            </div>
          ),
          key: `${product1?.sku} ${product2?.sku}`,
        })
      }
    }

    if (products) {
      generateSildes()
    }

    return generatedSlides
  }, [products])

  return (
    <SectionContainer width={width} wrapperClassName={isRTM && '!mt-2'}>
      {products?.length > 0 && (
        <>
          <div
            className={classNames(
              'relative py-20 hidden md:flex',
              styles.root
            )}>
            <Swiper
              modules={[Controller, Navigation, A11y]}
              slidesPerView='auto'
              spaceBetween={10}
              loop>
              {products.map((product, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className='flex flex-col gap-4 md:flex-row md:gap-10'>
                      {product && <ProductMiniCard {...product} />}
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div
            className={classNames(
              'relative py-20 pl-4 flex md:hidden',
              styles.root
            )}>
            <Swiper
              modules={[Controller, Navigation, A11y]}
              slidesPerView={1.05}
              loop>
              {slides.map(({ slide, key }) => {
                return <SwiperSlide key={key}>{slide}</SwiperSlide>
              })}
            </Swiper>
          </div>
        </>
      )}
    </SectionContainer>
  )
}
